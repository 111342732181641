<template>
   <div class="container">
    

    </div> 
</template>

<script>
export default {
    
}
</script>

<style scoped>
.container{
    width:20vw;
    height:90vh; 
   
}

</style>